var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YS6XRAL8hVIfMnSY7Nmnr"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/next_assets/e7410a107421259205196e5e0d8525e58e448357";

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN: string | undefined =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

console.log('SENTRY_DSN ', SENTRY_DSN);

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://8ff33325a5b94a3190120f02ec2c1178@o69961.ingest.sentry.io/6270999',
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
