export enum LOB {
    Partnership = 'partnership',
    Electronics = 'electronics',
    Internet = 'internet',
}

export enum PartnershipOnboardingStages {
    Welcome = 'welcome',
    PersonalDetails = 'personalDetails',
    CarDetails = 'carDetails',
    FinalScreen = 'finalScreen',
}

export enum API_TYPE {
    SEND_OTP = 'send_otp',
    VERIFY_OTP = 'verify_otp',
}

export enum INPUT_TYPE {
    EMAIL = 'Email',
    MOBILE_NUMBER = 'Mobile number',
}

export const SEND_OTP_BASE_URL = {
    EMAIL: '/auth/api/email/send-otp/',
    MOBILE_NUMBER: '/auth/api/send-otp/',
};

export const VERIFY_OTP_BASE_URL = {
    EMAIL: '/auth/api/email/verify-otp/',
    MOBILE_NUMBER: '/auth/api/verify-otp/',
};

export const ERROR_TYPE = {
    INCORRECT_OTP: 'Incorrect OTP',
    MANY_ATTEMPTS:
        'Hey there, too many login attempts! Please try again after 5 minutes.',
};

export const Relations = [
    'Self',
    'Brother',
    'Mother',
    'Father',
    'Sister',
    'Husband',
    'Wife',
    'Son',
    'Daughter',
    'Mother-in-Law',
    'Father-in-Law',
    'Son-in-Law',
    'Daughter-in-Law',
    'Brother-in-Law',
    'Sister-in-Law',
];

export const Genders = ['Male', 'Female', 'Other'];

export const CITY_ARRAY = [
    { key: 'aambala', value: 'Aambala' },
    { key: 'abohar', value: 'Abohar' },
    { key: 'abu', value: 'Abu' },
    { key: 'achalpur', value: 'Achalpur' },
    { key: 'adilabad', value: 'Adilabad' },
    { key: 'adoni', value: 'Adoni' },
    { key: 'agartala', value: 'Agartala' },
    { key: 'agra', value: 'Agra' },
    { key: 'ahmadabad', value: 'Ahmadabad' },
    { key: 'ahmadnagar', value: 'Ahmadnagar' },
    { key: 'ahmedabad', value: 'Ahmedabad' },
    { key: 'ahmednagar', value: 'Ahmednagar' },
    { key: 'ainapur', value: 'Ainapur' },
    { key: 'aizawl', value: 'Aizawl' },
    { key: 'aizwal', value: 'Aizwal' },
    { key: 'ajanta', value: 'Ajanta' },
    { key: 'ajmer', value: 'Ajmer' },
    { key: 'akbarpur', value: 'Akbarpur' },
    { key: 'akkalkot', value: 'Akkalkot' },
    { key: 'akola', value: 'Akola' },
    { key: 'alangudi', value: 'Alangudi' },
    { key: 'alappuzha', value: 'Alappuzha' },
    { key: 'alibaug', value: 'Alibaug' },
    { key: 'aligarh', value: 'Aligarh' },
    { key: 'alipore', value: 'Alipore' },
    { key: 'alipur_duar', value: 'Alipur Duar' },
    { key: 'alipurduar', value: 'Alipurduar' },
    { key: 'allahabad', value: 'Allahabad' },
    { key: 'alleppey', value: 'Alleppey' },
    { key: 'almora', value: 'Almora' },
    { key: 'alsisar', value: 'Alsisar' },
    { key: 'alur', value: 'Alur' },
    { key: 'alwar', value: 'Alwar' },
    { key: 'amalapuram', value: 'Amalapuram' },
    { key: 'amalner', value: 'Amalner' },
    { key: 'amaravati', value: 'Amaravati' },
    { key: 'ambaji', value: 'Ambaji' },
    { key: 'ambala', value: 'Ambala' },
    { key: 'ambikapur', value: 'Ambikapur' },
    { key: 'ambur', value: 'Ambur' },
    { key: 'amer', value: 'Amer' },
    { key: 'amla', value: 'Amla' },
    { key: 'amravati', value: 'Amravati' },
    { key: 'amreli', value: 'Amreli' },
    { key: 'amritsar', value: 'Amritsar' },
    { key: 'amroha', value: 'Amroha' },
    { key: 'anand', value: 'Anand' },
    { key: 'anantapur', value: 'Anantapur' },
    { key: 'anantnag', value: 'Anantnag' },
    { key: 'angara', value: 'Angara' },
    { key: 'anjangaon', value: 'Anjangaon' },
    { key: 'anjar', value: 'Anjar' },
    { key: 'ankleshwar bypass', value: 'Ankleshwar Bypass' },
    { key: 'ankleshwar', value: 'Ankleshwar' },
    { key: 'annavasal', value: 'Annavasal' },
    { key: 'annur', value: 'Annur' },
    { key: 'ara', value: 'Ara' },
    { key: 'arakonam', value: 'Arakonam' },
    { key: 'arambag', value: 'Arambag' },
    { key: 'arambagh', value: 'Arambagh' },
    { key: 'aranthangi', value: 'Aranthangi' },
    { key: 'araria', value: 'Araria' },
    { key: 'arcot', value: 'Arcot' },
    { key: 'arni', value: 'Arni' },
    { key: 'aroor', value: 'Aroor' },
    { key: 'arrah', value: 'Arrah' },
    { key: 'arsikere', value: 'Arsikere' },
    { key: 'aruppukkottai', value: 'Aruppukkottai' },
    { key: 'arvi', value: 'Arvi' },
    { key: 'asansol', value: 'Asansol' },
    { key: 'ashoknagar', value: 'Ashoknagar' },
    { key: 'ashta_mp', value: 'Ashta (MP)' },
    { key: 'ashta', value: 'Ashta' },
    { key: 'ashtamudi', value: 'Ashtamudi' },
    { key: 'ashti', value: 'Ashti' },
    { key: 'atmakur', value: 'Atmakur' },
    { key: 'atrauli', value: 'Atrauli' },
    { key: 'attingal', value: 'Attingal' },
    { key: 'attur', value: 'Attur' },
    { key: 'auli', value: 'Auli' },
    { key: 'aundha_nagnath', value: 'Aundha Nagnath' },
    { key: 'auraiya', value: 'Auraiya' },
    { key: 'aurangabad_bihar', value: 'Aurangabad, Bihar' },
    { key: 'aurangabad', value: 'Aurangabad' },
    { key: 'ayodhya', value: 'Ayodhya' },
    { key: 'azamgarh', value: 'Azamgarh' },
    { key: 'badami', value: 'Badami' },
    { key: 'baddi', value: 'Baddi' },
    { key: 'badrinath', value: 'Badrinath' },
    { key: 'bagalkot', value: 'Bagalkot' },
    { key: 'baghpat', value: 'Baghpat' },
    { key: 'bagnan', value: 'Bagnan' },
    { key: 'bagula', value: 'Bagula' },
    { key: 'bahadurgarh', value: 'Bahadurgarh' },
    { key: 'baharampur', value: 'Baharampur' },
    { key: 'baheri', value: 'Baheri' },
    { key: 'bahraich', value: 'Bahraich' },
    { key: 'baikunthpur', value: 'Baikunthpur' },
    { key: 'bailhongal', value: 'Bailhongal' },
    { key: 'balaghat', value: 'Balaghat' },
    { key: 'balangir', value: 'Balangir' },
    { key: 'balapur', value: 'Balapur' },
    { key: 'balasinor', value: 'Balasinor' },
    { key: 'balasore', value: 'Balasore' },
    { key: 'baleshwar', value: 'Baleshwar' },
    { key: 'ballari', value: 'Ballari' },
    { key: 'ballarpur', value: 'Ballarpur' },
    { key: 'ballia', value: 'Ballia' },
    { key: 'bally', value: 'Bally' },
    { key: 'balotra', value: 'Balotra' },
    { key: 'balrampur', value: 'Balrampur' },
    { key: 'balurghat', value: 'Balurghat' },
    { key: 'bamaniya', value: 'Bamaniya' },
    { key: 'bambora', value: 'Bambora' },
    { key: 'banda', value: 'Banda' },
    { key: 'bandhavgarh', value: 'Bandhavgarh' },
    { key: 'bandipur', value: 'Bandipur' },
    { key: 'bangalore', value: 'Bangalore' },
    { key: 'bangaon', value: 'Bangaon' },
    { key: 'banka', value: 'Banka' },
    { key: 'bankura', value: 'Bankura' },
    { key: 'banswara', value: 'Banswara' },
    { key: 'bantwal', value: 'Bantwal' },
    { key: 'bapatla', value: 'Bapatla' },
    { key: 'bara_banki', value: 'Bara Banki' },
    { key: 'barabanki', value: 'Barabanki' },
    { key: 'baramati', value: 'Baramati' },
    { key: 'baramula', value: 'Baramula' },
    { key: 'baran', value: 'Baran' },
    { key: 'baranagar', value: 'Baranagar' },
    { key: 'barara', value: 'Barara' },
    { key: 'barasat', value: 'Barasat' },
    { key: 'barauni', value: 'Barauni' },
    { key: 'baraut', value: 'Baraut' },
    { key: 'barbil', value: 'Barbil' },
    { key: 'bardoli', value: 'Bardoli' },
    { key: 'bareilly', value: 'Bareilly' },
    { key: 'baripada', value: 'Baripada' },
    { key: 'barmer', value: 'Barmer' },
    { key: 'barnala', value: 'Barnala' },
    { key: 'barrackpore', value: 'Barrackpore' },
    { key: 'barshi', value: 'Barshi' },
    { key: 'barwala', value: 'Barwala' },
    { key: 'barwani', value: 'Barwani' },
    { key: 'basavakalyan', value: 'Basavakalyan' },
    { key: 'basirhat', value: 'Basirhat' },
    { key: 'basti', value: 'Basti' },
    { key: 'batala', value: 'Batala' },
    { key: 'bathinda', value: 'Bathinda' },
    { key: 'beawar', value: 'Beawar' },
    { key: 'beed', value: 'Beed' },
    { key: 'beerapally', value: 'Beerapally' },
    { key: 'begusarai', value: 'Begusarai' },
    { key: 'behror', value: 'Behror' },
    { key: 'bela_pratapgarh', value: 'Bela Pratapgarh' },
    { key: 'belagavi', value: 'Belagavi' },
    { key: 'belgaum', value: 'Belgaum' },
    { key: 'bellary', value: 'Bellary' },
    { key: 'belur', value: 'Belur' },
    { key: 'bengaluru', value: 'Bengaluru' },
    { key: 'berhampore', value: 'Berhampore' },
    { key: 'berhampur', value: 'Berhampur' },
    { key: 'betalghat', value: 'Betalghat' },
    { key: 'bettiah', value: 'Bettiah' },
    { key: 'betul', value: 'Betul' },
    { key: 'bhadohi', value: 'Bhadohi' },
    { key: 'bhadra', value: 'Bhadra' },
    { key: 'bhadrachalam', value: 'Bhadrachalam' },
    { key: 'bhadrak', value: 'Bhadrak' },
    { key: 'bhadravati', value: 'Bhadravati' },
    { key: 'bhagalpur', value: 'Bhagalpur' },
    { key: 'bhandara', value: 'Bhandara' },
    { key: 'bhandardara', value: 'Bhandardara' },
    { key: 'bharatpur', value: 'Bharatpur' },
    { key: 'bharhut', value: 'Bharhut' },
    { key: 'bharuch_bypass', value: 'Bharuch Bypass' },
    { key: 'bharuch', value: 'Bharuch' },
    { key: 'bhatapara', value: 'Bhatapara' },
    { key: 'bhatpara', value: 'Bhatpara' },
    { key: 'bhavangadh', value: 'Bhavangadh' },
    { key: 'bhavnagar', value: 'Bhavnagar' },
    { key: 'bhawanipatna', value: 'Bhawanipatna' },
    { key: 'bhilai', value: 'Bhilai' },
    { key: 'bhilwara', value: 'Bhilwara' },
    { key: 'bhimtal', value: 'Bhimtal' },
    { key: 'bhind', value: 'Bhind' },
    { key: 'bhinmal', value: 'Bhinmal' },
    { key: 'bhiwadi', value: 'Bhiwadi' },
    { key: 'bhiwani', value: 'Bhiwani' },
    { key: 'bhojpur', value: 'Bhojpur' },
    { key: 'bhopal', value: 'Bhopal' },
    { key: 'bhubaneshwar', value: 'Bhubaneshwar' },
    { key: 'bhuj', value: 'Bhuj' },
    { key: 'bhusawal', value: 'Bhusawal' },
    { key: 'biaora', value: 'Biaora' },
    { key: 'bid', value: 'Bid' },
    { key: 'bidar', value: 'Bidar' },
    { key: 'bihar sharif', value: 'Bihar Sharif' },
    { key: 'bihta', value: 'Bihta' },
    { key: 'bijapur', value: 'Bijapur' },
    { key: 'bijnor', value: 'Bijnor' },
    { key: 'bikaner', value: 'Bikaner' },
    { key: 'bilaspur', value: 'Bilaspur' },
    { key: 'bilimora', value: 'Bilimora' },
    { key: 'binsar', value: 'Binsar' },
    { key: 'bir', value: 'Bir' },
    { key: 'bishnupur', value: 'Bishnupur' },
    { key: 'biswanath_chariali', value: 'Biswanath Chariali' },
    { key: 'bithur', value: 'Bithur' },
    { key: 'bodh_gaya', value: 'Bodh Gaya' },
    { key: 'bodhgaya', value: 'Bodhgaya' },
    { key: 'bodinayakanur', value: 'Bodinayakanur' },
    { key: 'bokaro', value: 'Bokaro' },
    { key: 'bolpur', value: 'Bolpur' },
    { key: 'bongaigaon', value: 'Bongaigaon' },
    { key: 'brahmapur', value: 'Brahmapur' },
    { key: 'budaun', value: 'Budaun' },
    { key: 'budge_budge', value: 'Budge Budge' },
    { key: 'bulandshahr', value: 'Bulandshahr' },
    { key: 'buldhana', value: 'Buldhana' },
    { key: 'bundi', value: 'Bundi' },
    { key: 'burdwan', value: 'Burdwan' },
    { key: 'burhanpur', value: 'Burhanpur' },
    { key: 'burhar', value: 'Burhar' },
    { key: 'buxar', value: 'Buxar' },
    { key: 'calicut', value: 'Calicut' },
    { key: 'canannore', value: 'Canannore' },
    { key: 'chaibasa', value: 'Chaibasa' },
    { key: 'chail', value: 'Chail' },
    { key: 'chakdaha', value: 'Chakdaha' },
    { key: 'chalisgaon', value: 'Chalisgaon' },
    { key: 'challakere', value: 'Challakere' },
    { key: 'chamarajanagar', value: 'Chamarajanagar' },
    { key: 'chamba', value: 'Chamba' },
    { key: 'chandausi', value: 'Chandausi' },
    { key: 'chandernagore', value: 'Chandernagore' },
    { key: 'chandigarh', value: 'Chandigarh' },
    { key: 'chandole', value: 'Chandole' },
    { key: 'chandpur', value: 'Chandpur' },
    { key: 'chandragiri', value: 'Chandragiri' },
    { key: 'chandrapur', value: 'Chandrapur' },
    { key: 'channarayapatna', value: 'Channarayapatna' },
    { key: 'chapra', value: 'Chapra' },
    { key: 'charkhi dadri', value: 'Charkhi Dadri' },
    { key: 'chauri_chaura', value: 'Chauri Chaura' },
    { key: 'cheeka', value: 'Cheeka' },
    { key: 'chengalpattu', value: 'Chengalpattu' },
    { key: 'chennai', value: 'Chennai' },
    { key: 'cherrapunji', value: 'Cherrapunji' },
    { key: 'cherthala', value: 'Cherthala' },
    { key: 'chhapra', value: 'Chhapra' },
    { key: 'chhatarpur', value: 'Chhatarpur' },
    { key: 'chhindwara', value: 'Chhindwara' },
    { key: 'chidambaram', value: 'Chidambaram' },
    { key: 'chikhli', value: 'Chikhli' },
    { key: 'chikkaballapur', value: 'Chikkaballapur' },
    { key: 'chikkamagaluru', value: 'Chikkamagaluru' },
    { key: 'chikmagalur', value: 'Chikmagalur' },
    { key: 'chilakaluripet', value: 'Chilakaluripet' },
    { key: 'chintamani', value: 'Chintamani' },
    { key: 'chiplun', value: 'Chiplun' },
    { key: 'chirala', value: 'Chirala' },
    { key: 'chitradurga', value: 'Chitradurga' },
    { key: 'chitrakoot', value: 'Chitrakoot' },
    { key: 'chittaurgarh', value: 'Chittaurgarh' },
    { key: 'chittoor', value: 'Chittoor' },
    { key: 'chittorgarh', value: 'Chittorgarh' },
    { key: 'chittur-tattamangalam', value: 'Chittur-Tattamangalam' },
    { key: 'chomu', value: 'Chomu' },
    { key: 'chopda', value: 'Chopda' },
    { key: 'churu', value: 'Churu' },
    { key: 'coimbatore', value: 'Coimbatore' },
    { key: 'contai', value: 'Contai' },
    { key: 'cooch_behar', value: 'Cooch Behar' },
    { key: 'coonoor', value: 'Coonoor' },
    { key: 'coorg', value: 'Coorg' },
    { key: 'corbett_national_park', value: 'Corbett National Park' },
    { key: 'cuddalore', value: 'Cuddalore' },
    { key: 'cuttack', value: 'Cuttack' },
    { key: 'dabhoi', value: 'Dabhoi' },
    { key: 'dabhol', value: 'Dabhol' },
    { key: 'dabhosa', value: 'Dabhosa' },
    { key: 'dabra', value: 'Dabra' },
    { key: 'dabwali', value: 'Dabwali' },
    { key: 'dahanu', value: 'Dahanu' },
    { key: 'dahod', value: 'Dahod' },
    { key: 'dalhousie', value: 'Dalhousie' },
    { key: 'daltonganj', value: 'Daltonganj' },
    { key: 'daman', value: 'Daman' },
    { key: 'damoh', value: 'Damoh' },
    { key: 'dandeli', value: 'Dandeli' },
    { key: 'dapoli', value: 'Dapoli' },
    { key: 'darbhanga', value: 'Darbhanga' },
    { key: 'darjeeling', value: 'Darjeeling' },
    { key: 'daryapur', value: 'Daryapur' },
    { key: 'datia', value: 'Datia' },
    { key: 'daudnagar', value: 'Daudnagar' },
    { key: 'daulatabad', value: 'Daulatabad' },
    { key: 'daund', value: 'Daund' },
    { key: 'dausa', value: 'Dausa' },
    { key: 'davangere', value: 'Davangere' },
    { key: 'davol', value: 'Davol' },
    { key: 'deglur', value: 'Deglur' },
    { key: 'dehradun', value: 'Dehradun' },
    { key: 'dehri', value: 'Dehri' },
    { key: 'delhi', value: 'Delhi' },
    { key: 'deoghar', value: 'Deoghar' },
    { key: 'deori', value: 'Deori' },
    { key: 'deoria_city', value: 'Deoria City' },
    { key: 'deoria', value: 'Deoria' },
    { key: 'deorukh', value: 'Deorukh' },
    { key: 'deshalpar', value: 'Deshalpar' },
    { key: 'deulgaon_mahi', value: 'Deulgaon Mahi' },
    { key: 'deulgaon_raja', value: 'Deulgaon Raja' },
    { key: 'devpar', value: 'Devpar' },
    { key: 'dewas', value: 'Dewas' },
    { key: 'dhampur', value: 'Dhampur' },
    { key: 'dhamtari', value: 'Dhamtari' },
    { key: 'dhanbad', value: 'Dhanbad' },
    { key: 'dhanera', value: 'Dhanera' },
    { key: 'dhar', value: 'Dhar' },
    { key: 'dharampur', value: 'Dharampur' },
    { key: 'dharamshala', value: 'Dharamshala' },
    { key: 'dharapuram', value: 'Dharapuram' },
    { key: 'dharmapuri', value: 'Dharmapuri' },
    { key: 'dharmavaram', value: 'Dharmavaram' },
    { key: 'dharmshala', value: 'Dharmshala' },
    { key: 'dharwad', value: 'Dharwad' },
    { key: 'dhaulpur', value: 'Dhaulpur' },
    { key: 'dhemaji', value: 'Dhemaji' },
    { key: 'dhenkanal', value: 'Dhenkanal' },
    { key: 'dhoki', value: 'Dhoki' },
    { key: 'dholka', value: 'Dholka' },
    { key: 'dholpur', value: 'Dholpur' },
    { key: 'dhoraji', value: 'Dhoraji' },
    { key: 'dhrangadhra', value: 'Dhrangadhra' },
    { key: 'dhubri', value: 'Dhubri' },
    { key: 'dhuburi', value: 'Dhuburi' },
    { key: 'dhule', value: 'Dhule' },
    { key: 'dhupguri', value: 'Dhupguri' },
    { key: 'dhuri', value: 'Dhuri' },
    { key: 'diamond_harbour', value: 'Diamond Harbour' },
    { key: 'dibrugarh', value: 'Dibrugarh' },
    { key: 'digha', value: 'Digha' },
    { key: 'digras', value: 'Digras' },
    { key: 'dinapur_nizamat', value: 'Dinapur Nizamat' },
    { key: 'dinara', value: 'Dinara' },
    { key: 'dindigul', value: 'Dindigul' },
    { key: 'dispur', value: 'Dispur' },
    { key: 'diu', value: 'Diu' },
    { key: 'diva', value: 'Diva' },
    { key: 'dive_agar', value: 'Dive Agar' },
    { key: 'doda', value: 'Doda' },
    { key: 'doddaballapura', value: 'Doddaballapura' },
    { key: 'dongaon', value: 'Dongaon' },
    { key: 'dooars', value: 'Dooars' },
    { key: 'dowlaiswaram', value: 'Dowlaiswaram' },
    { key: 'dr_ambedkar_nagar (mhow)', value: 'Dr. Ambedkar Nagar (Mhow)' },
    { key: 'duliajan', value: 'Duliajan' },
    { key: 'dum_dum', value: 'Dum Dum' },
    { key: 'dumka', value: 'Dumka' },
    { key: 'dunawa', value: 'Dunawa' },
    { key: 'dungarpur', value: 'Dungarpur' },
    { key: 'durg', value: 'Durg' },
    { key: 'durgapur', value: 'Durgapur' },
    { key: 'durshet', value: 'Durshet' },
    { key: 'dwarka', value: 'Dwarka' },
    { key: 'ellenabad', value: 'Ellenabad' },
    { key: 'eluru', value: 'Eluru' },
    { key: 'erandol', value: 'Erandol' },
    { key: 'erattupetta', value: 'Erattupetta' },
    { key: 'erichanatham', value: 'Erichanatham' },
    { key: 'erode_bypass', value: 'Erode Bypass' },
    { key: 'erode', value: 'Erode' },
    { key: 'etah', value: 'Etah' },
    { key: 'etawah', value: 'Etawah' },
    { key: 'faizabad-ayodhya', value: 'Faizabad-Ayodhya' },
    { key: 'faizabad', value: 'Faizabad' },
    { key: 'faizpur', value: 'Faizpur' },
    { key: 'faridabad', value: 'Faridabad' },
    { key: 'faridkot', value: 'Faridkot' },
    { key: 'farrukhabad / fatehgarh', value: 'Farrukhabad / Fatehgarh' },
    { key: 'farrukhabad', value: 'Farrukhabad' },
    { key: 'fatehabad', value: 'Fatehabad' },
    { key: 'fatehgarhsahib', value: 'FatehgarhSahib' },
    { key: 'fatehpur_sikri', value: 'Fatehpur Sikri' },
    { key: 'fatehpur', value: 'Fatehpur' },
    { key: 'fazilka', value: 'Fazilka' },
    { key: 'firozabad', value: 'Firozabad' },
    { key: 'firozpur_jhirka', value: 'Firozpur Jhirka' },
    { key: 'firozpur', value: 'Firozpur' },
    { key: 'gadag-betageri', value: 'Gadag-Betageri' },
    { key: 'gadhakada', value: 'Gadhakada' },
    { key: 'gadhinglaj', value: 'Gadhinglaj' },
    { key: 'ganapatipule', value: 'Ganapatipule' },
    { key: 'ganaur', value: 'Ganaur' },
    { key: 'gandhidham', value: 'Gandhidham' },
    { key: 'gandhinagar', value: 'Gandhinagar' },
    { key: 'gangakhed', value: 'Gangakhed' },
    { key: 'ganganagar', value: 'Ganganagar' },
    { key: 'gangapur', value: 'Gangapur' },
    { key: 'gangarampur', value: 'Gangarampur' },
    { key: 'gangavaram', value: 'Gangavaram' },
    { key: 'gangavathi', value: 'Gangavathi' },
    { key: 'gangotri', value: 'Gangotri' },
    { key: 'gangtok', value: 'Gangtok' },
    { key: 'gargoti', value: 'Gargoti' },
    { key: 'garhmukteshwar', value: 'Garhmukteshwar' },
    { key: 'garhwal', value: 'Garhwal' },
    { key: 'gauriganj', value: 'Gauriganj' },
    { key: 'gaya', value: 'Gaya' },
    { key: 'gharaunda', value: 'Gharaunda' },
    { key: 'ghaziabad', value: 'Ghaziabad' },
    { key: 'ghazipur', value: 'Ghazipur' },
    { key: 'ghodbandar', value: 'Ghodbandar' },
    { key: 'giridih', value: 'Giridih' },
    { key: 'goa', value: 'Goa' },
    { key: 'goalpara', value: 'Goalpara' },
    { key: 'gobardanga', value: 'Gobardanga' },
    { key: 'gobichettipalayam', value: 'Gobichettipalayam' },
    { key: 'godda', value: 'Godda' },
    { key: 'godhra', value: 'Godhra' },
    { key: 'gohana', value: 'Gohana' },
    { key: 'gokharna', value: 'Gokharna' },
    { key: 'gola_gokarannath', value: 'Gola Gokarannath' },
    { key: 'golaghat', value: 'Golaghat' },
    { key: 'gonda', value: 'Gonda' },
    { key: 'gondal', value: 'Gondal' },
    { key: 'gondia', value: 'Gondia' },
    { key: 'gopalganj', value: 'Gopalganj' },
    { key: 'gorakhpur', value: 'Gorakhpur' },
    { key: 'greater_noida', value: 'Greater Noida' },
    { key: 'gudivada', value: 'Gudivada' },
    { key: 'gudiyatham', value: 'Gudiyatham' },
    { key: 'gudluru', value: 'Gudluru' },
    { key: 'gudur', value: 'Gudur' },
    { key: 'gullapur', value: 'Gullapur' },
    { key: 'gulmarg', value: 'Gulmarg' },
    { key: 'guna', value: 'Guna' },
    { key: 'guntakal', value: 'Guntakal' },
    { key: 'guntur', value: 'Guntur' },
    { key: 'gurdaspur', value: 'Gurdaspur' },
    { key: 'gurgaon', value: 'Gurgaon' },
    { key: 'gurugram', value: 'Gurugram' },
    { key: 'guruvayoor', value: 'Guruvayoor' },
    { key: 'guwahati_airport_borjhar', value: 'Guwahati Airport Borjhar' },
    { key: 'guwahati', value: 'Guwahati' },
    { key: 'gwalior', value: 'Gwalior' },
    { key: 'gyalshing', value: 'Gyalshing' },
    { key: 'habra', value: 'Habra' },
    { key: 'hajipur', value: 'Hajipur' },
    { key: 'haldia', value: 'Haldia' },
    { key: 'haldwani', value: 'Haldwani' },
    { key: 'halebid', value: 'Halebid' },
    { key: 'halisahar', value: 'Halisahar' },
    { key: 'hamirpur', value: 'Hamirpur' },
    { key: 'halol', value: 'Halol' },
    { key: 'hampi-hospet', value: 'Hampi-Hospet' },
    { key: 'hampi', value: 'Hampi' },
    { key: 'hanagal', value: 'Hanagal' },
    { key: 'hansi', value: 'Hansi' },
    { key: 'hanumangarh_town', value: 'Hanumangarh Town' },
    { key: 'hanumangarh', value: 'Hanumangarh' },
    { key: 'haora', value: 'Haora' },
    { key: 'hapur', value: 'Hapur' },
    { key: 'harda', value: 'Harda' },
    { key: 'hardoi', value: 'Hardoi' },
    { key: 'haridwar', value: 'Haridwar' },
    { key: 'harihar', value: 'Harihar' },
    { key: 'harishi', value: 'Harishi' },
    { key: 'hassan', value: 'Hassan' },
    { key: 'hathras', value: 'Hathras' },
    { key: 'hatta', value: 'Hatta' },
    { key: 'haveri', value: 'Haveri' },
    { key: 'hazaribag', value: 'Hazaribag' },
    { key: 'hazaribagh', value: 'Hazaribagh' },
    { key: 'himatnagar', value: 'Himatnagar' },
    { key: 'hindaun', value: 'Hindaun' },
    { key: 'hinganghat', value: 'Hinganghat' },
    { key: 'hingoli', value: 'Hingoli' },
    { key: 'hiriyur', value: 'Hiriyur' },
    { key: 'hisar', value: 'Hisar' },
    { key: 'hoshangabad', value: 'Hoshangabad' },
    { key: 'hoshiarpur', value: 'Hoshiarpur' },
    { key: 'hospet', value: 'Hospet' },
    { key: 'hosur', value: 'Hosur' },
    { key: 'hubballi-dharwad', value: 'Hubballi-Dharwad' },
    { key: 'hubli', value: 'Hubli' },
    { key: 'hugli', value: 'Hugli' },
    { key: 'humnabad', value: 'Humnabad' },
    { key: 'hyderabad', value: 'Hyderabad' },
    { key: 'ichalkaranji', value: 'Ichalkaranji' },
    { key: 'idukki', value: 'Idukki' },
    { key: 'igatpuri', value: 'Igatpuri' },
    { key: 'imphal', value: 'Imphal' },
    { key: 'indore', value: 'Indore' },
    { key: 'ingraj_bazar', value: 'Ingraj Bazar' },
    { key: 'islampur', value: 'Islampur' },
    { key: 'itanagar', value: 'Itanagar' },
    { key: 'itarsi', value: 'Itarsi' },
    { key: 'jabalpur', value: 'Jabalpur' },
    { key: 'jagdalpur', value: 'Jagdalpur' },
    { key: 'jagraon', value: 'Jagraon' },
    { key: 'jagtial', value: 'Jagtial' },
    { key: 'jaipur', value: 'Jaipur' },
    { key: 'jaisalmer', value: 'Jaisalmer' },
    { key: 'jaisinghpur', value: 'Jaisinghpur' },
    { key: 'jalalabad', value: 'Jalalabad' },
    { key: 'jalandhar', value: 'Jalandhar' },
    { key: 'jalaun', value: 'Jalaun' },
    { key: 'jalgaon', value: 'Jalgaon' },
    { key: 'jalna', value: 'Jalna' },
    { key: 'jalor', value: 'Jalor' },
    { key: 'jalpaiguri', value: 'Jalpaiguri' },
    { key: 'jamalpur', value: 'Jamalpur' },
    { key: 'jambugodha', value: 'Jambugodha' },
    { key: 'jamkhandi', value: 'Jamkhandi' },
    { key: 'jammu', value: 'Jammu' },
    { key: 'jamnagar', value: 'Jamnagar' },
    { key: 'jamshedpur', value: 'Jamshedpur' },
    { key: 'jamui', value: 'Jamui' },
    { key: 'janjgir', value: 'Janjgir' },
    { key: 'jaora', value: 'Jaora' },
    { key: 'jaunpur', value: 'Jaunpur' },
    { key: 'jawhar', value: 'Jawhar' },
    { key: 'jayankondam', value: 'Jayankondam' },
    { key: 'jaysingpur', value: 'Jaysingpur' },
    { key: 'jedarpalayam', value: 'Jedarpalayam' },
    { key: 'jehanabad', value: 'Jehanabad' },
    { key: 'jetpur', value: 'Jetpur' },
    { key: 'jhabua', value: 'Jhabua' },
    { key: 'jhajjar', value: 'Jhajjar' },
    { key: 'jhalawar', value: 'Jhalawar' },
    { key: 'jhansi', value: 'Jhansi' },
    { key: 'jhargram', value: 'Jhargram' },
    { key: 'jharia', value: 'Jharia' },
    { key: 'jharsuguda', value: 'Jharsuguda' },
    { key: 'jhumri_telaiya', value: 'Jhumri Telaiya' },
    { key: 'jhunjhunu', value: 'Jhunjhunu' },
    { key: 'jiaganj', value: 'Jiaganj' },
    { key: 'jind', value: 'Jind' },
    { key: 'jindal', value: 'Jindal' },
    { key: 'jodhpur', value: 'Jodhpur' },
    { key: 'jojawar', value: 'Jojawar' },
    { key: 'jorhat', value: 'Jorhat' },
    { key: 'junagadh', value: 'Junagadh' },
    { key: 'k.paramathi', value: 'K.Paramathi' },
    { key: 'kabini', value: 'Kabini' },
    { key: 'kadapa', value: 'Kadapa' },
    { key: 'kadayanallur', value: 'Kadayanallur' },
    { key: 'kadepur', value: 'Kadepur' },
    { key: 'kadiri', value: 'Kadiri' },
    { key: 'kadur', value: 'Kadur' },
    { key: 'kagal', value: 'Kagal' },
    { key: 'kaithal', value: 'Kaithal' },
    { key: 'kakinada', value: 'Kakinada' },
    { key: 'kalaburagi', value: 'Kalaburagi' },
    { key: 'kalamb', value: 'Kalamb' },
    { key: 'kalimpong', value: 'Kalimpong' },
    { key: 'kallakkurichi', value: 'Kallakkurichi' },
    { key: 'kallur', value: 'Kallur' },
    { key: 'kalna', value: 'Kalna' },
    { key: 'kalol', value: 'Kalol' },
    { key: 'kalpetta', value: 'Kalpetta' },
    { key: 'kalyan', value: 'Kalyan' },
    { key: 'kamareddy', value: 'Kamareddy' },
    { key: 'kamarhati', value: 'Kamarhati' },
    { key: 'kanatal', value: 'Kanatal' },
    { key: 'kanchipuram', value: 'Kanchipuram' },
    { key: 'kanchrapara', value: 'Kanchrapara' },
    { key: 'kandhla', value: 'Kandhla' },
    { key: 'kandi', value: 'Kandi' },
    { key: 'kandla', value: 'Kandla' },
    { key: 'kandukur', value: 'Kandukur' },
    { key: 'kangeyam', value: 'Kangeyam' },
    { key: 'kangra', value: 'Kangra' },
    { key: 'kanha', value: 'Kanha' },
    { key: 'kanhangad', value: 'Kanhangad' },
    { key: 'kanker', value: 'Kanker' },
    { key: 'kannauj', value: 'Kannauj' },
    { key: 'kanniyakumari', value: 'Kanniyakumari' },
    { key: 'kannur', value: 'Kannur' },
    { key: 'kanpur', value: 'Kanpur' },
    { key: 'kansabel', value: 'Kansabel' },
    { key: 'kanyakumari', value: 'Kanyakumari' },
    { key: 'kapadvanj', value: 'Kapadvanj' },
    { key: 'kapu', value: 'Kapu' },
    { key: 'kapurthala', value: 'Kapurthala' },
    { key: 'karad', value: 'Karad' },
    { key: 'karaikal', value: 'Karaikal' },
    { key: 'karaikudi', value: 'Karaikudi' },
    { key: 'karanja', value: 'Karanja' },
    { key: 'karauli', value: 'Karauli' },
    { key: 'kargil', value: 'Kargil' },
    { key: 'karimganj', value: 'Karimganj' },
    { key: 'karimnagar', value: 'Karimnagar' },
    { key: 'karjat', value: 'Karjat' },
    { key: 'karli', value: 'Karli' },
    { key: 'karnal', value: 'Karnal' },
    { key: 'karur_bypass', value: 'Karur Bypass' },
    { key: 'karur', value: 'Karur' },
    { key: 'karwar', value: 'Karwar' },
    { key: 'kasal', value: 'Kasal' },
    { key: 'kasargod', value: 'Kasargod' },
    { key: 'kasauli', value: 'Kasauli' },
    { key: 'kasganj', value: 'Kasganj' },
    { key: 'kashid', value: 'Kashid' },
    { key: 'kashipur', value: 'Kashipur' },
    { key: 'katapady', value: 'Katapady' },
    { key: 'kathua', value: 'Kathua' },
    { key: 'katihar', value: 'Katihar' },
    { key: 'katni', value: 'Katni' },
    { key: 'katol', value: 'Katol' },
    { key: 'katpadi', value: 'Katpadi' },
    { key: 'katra', value: 'Katra' },
    { key: 'kausani', value: 'Kausani' },
    { key: 'kavali', value: 'Kavali' },
    { key: 'kayamkulam', value: 'Kayamkulam' },
    { key: 'kaza', value: 'Kaza' },
    { key: 'kaziranga', value: 'Kaziranga' },
    { key: 'kedarnath', value: 'Kedarnath' },
    { key: 'kendrapara', value: 'Kendrapara' },
    { key: 'kendujhar', value: 'Kendujhar' },
    { key: 'keonjhar', value: 'Keonjhar' },
    { key: 'keshod', value: 'Keshod' },
    { key: 'kevadia', value: 'Kevadia' },
    { key: 'khagaria', value: 'Khagaria' },
    { key: 'khajjiar', value: 'Khajjiar' },
    { key: 'khajuraho', value: 'Khajuraho' },
    { key: 'khalilabad', value: 'Khalilabad' },
    { key: 'khambhalia', value: 'Khambhalia' },
    { key: 'khambhat', value: 'Khambhat' },
    { key: 'khammam', value: 'Khammam' },
    { key: 'khanapur', value: 'Khanapur' },
    { key: 'khandala', value: 'Khandala' },
    { key: 'khandwa', value: 'Khandwa' },
    { key: 'khanna', value: 'Khanna' },
    { key: 'kharagpur', value: 'Kharagpur' },
    { key: 'khargapur', value: 'Khargapur' },
    { key: 'khargone', value: 'Khargone' },
    { key: 'kheda', value: 'Kheda' },
    { key: 'khetri', value: 'Khetri' },
    { key: 'khilchipur', value: 'Khilchipur' },
    { key: 'khimana', value: 'Khimana' },
    { key: 'khimsar', value: 'Khimsar' },
    { key: 'khurja', value: 'Khurja' },
    { key: 'kichha', value: 'Kichha' },
    { key: 'kinnaur', value: 'Kinnaur' },
    { key: 'kishanganj', value: 'Kishanganj' },
    { key: 'kishangarh', value: 'Kishangarh' },
    { key: 'kochi', value: 'Kochi' },
    { key: 'kochin', value: 'Kochin' },
    { key: 'kodaikanal', value: 'Kodaikanal' },
    { key: 'kodinar', value: 'Kodinar' },
    { key: 'kodungallur', value: 'Kodungallur' },
    { key: 'kohima', value: 'Kohima' },
    { key: 'kolar', value: 'Kolar' },
    { key: 'kolhapur', value: 'Kolhapur' },
    { key: 'kolhar', value: 'Kolhar' },
    { key: 'kolkata', value: 'Kolkata' },
    { key: 'kollam', value: 'Kollam' },
    { key: 'kollipara', value: 'Kollipara' },
    { key: 'kommuru', value: 'Kommuru' },
    { key: 'konark', value: 'Konark' },
    { key: 'kopargaon', value: 'Kopargaon' },
    { key: 'koppal', value: 'Koppal' },
    { key: 'koraput', value: 'Koraput' },
    { key: 'korba', value: 'Korba' },
    { key: 'kota', value: 'Kota' },
    { key: 'kotagiri', value: 'Kotagiri' },
    { key: 'kothagudem', value: 'Kothagudem' },
    { key: 'kotkapura', value: 'KotKapura' },
    { key: 'kotputli', value: 'Kotputli' },
    { key: 'kottayam', value: 'Kottayam' },
    { key: 'kotturu', value: 'Kotturu' },
    { key: 'kovalam', value: 'Kovalam' },
    { key: 'kovilpatti', value: 'Kovilpatti' },
    { key: 'kovur', value: 'Kovur' },
    { key: 'kozhikode', value: 'Kozhikode' },
    { key: 'krishnagiri', value: 'Krishnagiri' },
    { key: 'krishnanagar', value: 'Krishnanagar' },
    { key: 'kuchaman', value: 'Kuchaman' },
    { key: 'kufri', value: 'Kufri' },
    { key: 'kulathur', value: 'Kulathur' },
    { key: 'kullu', value: 'Kullu' },
    { key: 'kumarakom', value: 'Kumarakom' },
    { key: 'kumbakonam', value: 'Kumbakonam' },
    { key: 'kumbalgarh', value: 'Kumbalgarh' },
    { key: 'kumily', value: 'Kumily' },
    { key: 'kumta', value: 'Kumta' },
    { key: 'kunigal', value: 'Kunigal' },
    { key: 'kunnamkulam', value: 'Kunnamkulam' },
    { key: 'kurha', value: 'Kurha' },
    { key: 'kurnool', value: 'Kurnool' },
    { key: 'kurseong', value: 'Kurseong' },
    { key: 'kurukshetra', value: 'Kurukshetra' },
    { key: 'kushinagar', value: 'Kushinagar' },
    { key: 'kushtagi', value: 'Kushtagi' },
    { key: 'lachung', value: 'Lachung' },
    { key: 'lakhanpur', value: 'Lakhanpur' },
    { key: 'lakhimpur', value: 'Lakhimpur' },
    { key: 'lakhisarai', value: 'Lakhisarai' },
    { key: 'lakshadweep', value: 'Lakshadweep' },
    { key: 'lalitpur', value: 'Lalitpur' },
    { key: 'latur', value: 'Latur' },
    { key: 'leh', value: 'Leh' },
    { key: 'likhala', value: 'Likhala' },
    { key: 'lodhava', value: 'Lodhava' },
    { key: 'lohara', value: 'Lohara' },
    { key: 'lonavala', value: 'Lonavala' },
    { key: 'loni', value: 'Loni' },
    { key: 'lothal', value: 'Lothal' },
    { key: 'lucknow', value: 'Lucknow' },
    { key: 'ludhiana', value: 'Ludhiana' },
    { key: 'lumbini', value: 'Lumbini' },
    { key: 'lunglei', value: 'Lunglei' },
    { key: 'machilipatnam', value: 'Machilipatnam' },
    { key: 'madanapalle', value: 'Madanapalle' },
    { key: 'madgaon', value: 'Madgaon' },
    { key: 'madhepura', value: 'Madhepura' },
    { key: 'madhubani', value: 'Madhubani' },
    { key: 'madikeri', value: 'Madikeri' },
    { key: 'madurai', value: 'Madurai' },
    { key: 'mahabaleshwar', value: 'Mahabaleshwar' },
    { key: 'mahabalipuram', value: 'Mahabalipuram' },
    { key: 'maharajganj', value: 'Maharajganj' },
    { key: 'mahasamund', value: 'Mahasamund' },
    { key: 'mahbubnagar', value: 'Mahbubnagar' },
    { key: 'mahe', value: 'Mahe' },
    { key: 'mahemdavad', value: 'Mahemdavad' },
    { key: 'mahesana', value: 'Mahesana' },
    { key: 'maheshwar', value: 'Maheshwar' },
    { key: 'mahoba', value: 'Mahoba' },
    { key: 'mahuva', value: 'Mahuva' },
    { key: 'mainpuri', value: 'Mainpuri' },
    { key: 'majalgaon', value: 'Majalgaon' },
    { key: 'malappuram', value: 'Malappuram' },
    { key: 'malavalli', value: 'Malavalli' },
    { key: 'malda', value: 'Malda' },
    { key: 'malegaon', value: 'Malegaon' },
    { key: 'malerkotla', value: 'Malerkotla' },
    { key: 'malkapur', value: 'Malkapur' },
    { key: 'mallapura', value: 'Mallapura' },
    { key: 'malout', value: 'Malout' },
    { key: 'malpe', value: 'Malpe' },
    { key: 'malshej_ghat', value: 'Malshej Ghat' },
    { key: 'malvan', value: 'Malvan' },
    { key: 'mamallapuram', value: 'Mamallapuram' },
    { key: 'manachanallur', value: 'Manachanallur' },
    { key: 'manali', value: 'Manali' },
    { key: 'mancherial', value: 'Mancherial' },
    { key: 'mandapeta', value: 'Mandapeta' },
    { key: 'mandavi', value: 'Mandavi' },
    { key: 'mandawa', value: 'Mandawa' },
    { key: 'mandi_dabwali', value: 'Mandi Dabwali' },
    { key: 'mandi', value: 'Mandi' },
    { key: 'mandideep', value: 'Mandideep' },
    { key: 'mandla', value: 'Mandla' },
    { key: 'mandormoni', value: 'Mandormoni' },
    { key: 'mandsaur', value: 'Mandsaur' },
    { key: 'mandvi', value: 'Mandvi' },
    { key: 'mandya', value: 'Mandya' },
    { key: 'manesar', value: 'Manesar' },
    { key: 'mangalore', value: 'Mangalore' },
    { key: 'mangaluru', value: 'Mangaluru' },
    { key: 'mangan', value: 'Mangan' },
    { key: 'manikaran', value: 'Manikaran' },
    { key: 'manjari', value: 'Manjari' },
    { key: 'manjeri', value: 'Manjeri' },
    { key: 'manmad', value: 'Manmad' },
    { key: 'mansa', value: 'Mansa' },
    { key: 'mararri', value: 'Mararri' },
    { key: 'marchula', value: 'Marchula' },
    { key: 'marlapadu', value: 'Marlapadu' },
    { key: 'masaurhi', value: 'Masaurhi' },
    { key: 'matheran', value: 'Matheran' },
    { key: 'mathura', value: 'Mathura' },
    { key: 'mattancheri', value: 'Mattancheri' },
    { key: 'mattannur', value: 'Mattannur' },
    { key: 'mau', value: 'Mau' },
    { key: 'mauranipur', value: 'Mauranipur' },
    { key: 'mayiladuthurai', value: 'Mayiladuthurai' },
    { key: 'mcleodganj', value: 'Mcleodganj' },
    { key: 'meerut', value: 'Meerut' },
    { key: 'meghpar', value: 'Meghpar' },
    { key: 'mehsana', value: 'Mehsana' },
    { key: 'merta', value: 'Merta' },
    { key: 'mettupalayam', value: 'Mettupalayam' },
    { key: 'mettur', value: 'Mettur' },
    { key: 'midnapore', value: 'Midnapore' },
    { key: 'midnapur', value: 'Midnapur' },
    { key: 'miryalaguda', value: 'Miryalaguda' },
    { key: 'mirzapur-vindhyachal', value: 'Mirzapur-Vindhyachal' },
    { key: 'mirzapur', value: 'Mirzapur' },
    { key: 'modasa', value: 'Modasa' },
    { key: 'modinagar', value: 'Modinagar' },
    { key: 'moga', value: 'Moga' },
    { key: 'mohali', value: 'Mohali' },
    { key: 'mohammadpur', value: 'Mohammadpur' },
    { key: 'mon', value: 'Mon' },
    { key: 'moodbidri', value: 'Moodbidri' },
    { key: 'moradabad', value: 'Moradabad' },
    { key: 'morbi', value: 'Morbi' },
    { key: 'morena', value: 'Morena' },
    { key: 'mota_zinzuda', value: 'Mota Zinzuda' },
    { key: 'motihari', value: 'Motihari' },
    { key: 'mount_abu', value: 'Mount Abu' },
    { key: 'mughalsarai', value: 'Mughalsarai' },
    { key: 'mukhiguda', value: 'Mukhiguda' },
    { key: 'mukteshwar', value: 'Mukteshwar' },
    { key: 'muktsar', value: 'Muktsar' },
    { key: 'mullana', value: 'Mullana' },
    { key: 'mumanvas', value: 'Mumanvas' },
    { key: 'mumbai', value: 'Mumbai' },
    { key: 'mundra', value: 'Mundra' },
    { key: 'munger', value: 'Munger' },
    { key: 'munnar', value: 'Munnar' },
    { key: 'murdeshwar', value: 'Murdeshwar' },
    { key: 'murshidabad', value: 'Murshidabad' },
    { key: 'murud_janjira', value: 'Murud Janjira' },
    { key: 'murwara', value: 'Murwara' },
    { key: 'musiri', value: 'Musiri' },
    { key: 'mussoorie', value: 'Mussoorie' },
    { key: 'muthur', value: 'Muthur' },
    { key: 'muvattupuzha', value: 'Muvattupuzha' },
    { key: 'muzaffarnagar', value: 'Muzaffarnagar' },
    { key: 'muzaffarpur', value: 'Muzaffarpur' },
    { key: 'mysore', value: 'Mysore' },
    { key: 'mysuru', value: 'Mysuru' },
    { key: 'nabadwip', value: 'Nabadwip' },
    { key: 'nabha', value: 'Nabha' },
    { key: 'nadiad', value: 'Nadiad' },
    { key: 'nadukani', value: 'Nadukani' },
    { key: 'nagaon', value: 'Nagaon' },
    { key: 'nagapattinam', value: 'Nagapattinam' },
    { key: 'nagappattinam', value: 'Nagappattinam' },
    { key: 'nagara', value: 'Nagara' },
    { key: 'nagarhole', value: 'Nagarhole' },
    { key: 'nagarjunakoṇḍa', value: 'Nagarjunakoṇḍa' },
    { key: 'nagaur_fort', value: 'Nagaur Fort' },
    { key: 'nagaur', value: 'Nagaur' },
    { key: 'nagda', value: 'Nagda' },
    { key: 'nagel', value: 'Nagel' },
    { key: 'nagercoil', value: 'Nagercoil' },
    { key: 'nagina', value: 'Nagina' },
    { key: 'nagoor', value: 'Nagoor' },
    { key: 'nagore', value: 'Nagore' },
    { key: 'nagothane', value: 'Nagothane' },
    { key: 'nagpur', value: 'Nagpur' },
    { key: 'nahan', value: 'Nahan' },
    { key: 'naigaon', value: 'Naigaon' },
    { key: 'nainital', value: 'Nainital' },
    { key: 'nakodar', value: 'Nakodar' },
    { key: 'nalbari', value: 'Nalbari' },
    { key: 'naldhera', value: 'Naldhera' },
    { key: 'nalegaon', value: 'Nalegaon' },
    { key: 'nalgonda', value: 'Nalgonda' },
    { key: 'namakkal', value: 'Namakkal' },
    { key: 'nanded', value: 'Nanded' },
    { key: 'nandgaon', value: 'Nandgaon' },
    { key: 'nandivelugu', value: 'Nandivelugu' },
    { key: 'nandura', value: 'Nandura' },
    { key: 'nandyal', value: 'Nandyal' },
    { key: 'nangal', value: 'Nangal' },
    { key: 'nanjangud', value: 'Nanjangud' },
    { key: 'nanpara', value: 'Nanpara' },
    { key: 'napne', value: 'Napne' },
    { key: 'narasaraopet', value: 'Narasaraopet' },
    { key: 'narayanpur', value: 'Narayanpur' },
    { key: 'narnaul', value: 'Narnaul' },
    { key: 'narsapur', value: 'Narsapur' },
    { key: 'narsapuram', value: 'Narsapuram' },
    { key: 'narsi', value: 'Narsi' },
    { key: 'narsimhapur', value: 'Narsimhapur' },
    { key: 'narsinghgarh', value: 'Narsinghgarh' },
    { key: 'narsinghpur', value: 'Narsinghpur' },
    { key: 'narwana', value: 'Narwana' },
    { key: 'narwar', value: 'Narwar' },
    { key: 'nashik', value: 'Nashik' },
    { key: 'nasik', value: 'Nasik' },
    { key: 'nathdwara', value: 'Nathdwara' },
    { key: 'naugachhia', value: 'Naugachhia' },
    { key: 'navagam', value: 'Navagam' },
    { key: 'navi_mumbai', value: 'Navi Mumbai' },
    { key: 'navsari', value: 'Navsari' },
    { key: 'nawada', value: 'Nawada' },
    { key: 'nawalgarh', value: 'Nawalgarh' },
    { key: 'nawanshahr', value: 'Nawanshahr' },
    { key: 'neemuch', value: 'Neemuch' },
    { key: 'nelamangala', value: 'Nelamangala' },
    { key: 'ner', value: 'Ner' },
    { key: 'neral', value: 'Neral' },
    { key: 'new_delhi', value: 'New Delhi' },
    { key: 'neyveli', value: 'Neyveli' },
    { key: 'nilgiri', value: 'Nilgiri' },
    { key: 'nirmal', value: 'Nirmal' },
    { key: 'nitk_surathkal', value: 'Nitk Surathkal' },
    { key: 'nitte', value: 'Nitte' },
    { key: 'nizamabad', value: 'Nizamabad' },
    { key: 'nizampur', value: 'Nizampur' },
    { key: 'noida', value: 'Noida' },
    { key: 'north_lakhimpur', value: 'North Lakhimpur' },
    { key: 'nowgong', value: 'Nowgong' },
    { key: 'nuzividu', value: 'Nuzividu' },
    { key: 'okha', value: 'Okha' },
    { key: 'omalur', value: 'Omalur' },
    { key: 'ongole', value: 'Ongole' },
    { key: 'ooty', value: 'Ooty' },
    { key: 'orai', value: 'Orai' },
    { key: 'orchha', value: 'Orchha' },
    { key: 'osian', value: 'Osian' },
    { key: 'osmanabad', value: 'Osmanabad' },
    { key: 'pachmarhi', value: 'Pachmarhi' },
    { key: 'pachora', value: 'Pachora' },
    { key: 'pachore', value: 'Pachore' },
    { key: 'padra', value: 'Padra' },
    { key: 'padrauna', value: 'Padrauna' },
    { key: 'pahalgam', value: 'Pahalgam' },
    { key: 'pahur', value: 'Pahur' },
    { key: 'paithan', value: 'Paithan' },
    { key: 'pakur', value: 'Pakur' },
    { key: 'palakkad', value: 'Palakkad' },
    { key: 'palakollu', value: 'Palakollu' },
    { key: 'palam', value: 'Palam' },
    { key: 'palampur', value: 'Palampur' },
    { key: 'palani', value: 'Palani' },
    { key: 'palanpur', value: 'Palanpur' },
    { key: 'palashi', value: 'Palashi' },
    { key: 'palayamkottai', value: 'Palayamkottai' },
    { key: 'pali', value: 'Pali' },
    { key: 'palitana', value: 'Palitana' },
    { key: 'palladam', value: 'Palladam' },
    { key: 'pallakad', value: 'Pallakad' },
    { key: 'palwal', value: 'Palwal' },
    { key: 'pamarru', value: 'Pamarru' },
    { key: 'pamban', value: 'Pamban' },
    { key: 'pamuru', value: 'Pamuru' },
    { key: 'panaji', value: 'Panaji' },
    { key: 'panchgani', value: 'Panchgani' },
    { key: 'panchkula', value: 'Panchkula' },
    { key: 'pandamangalam', value: 'Pandamangalam' },
    { key: 'pandharpur', value: 'Pandharpur' },
    { key: 'paneli moti', value: 'Paneli Moti' },
    { key: 'panhala', value: 'Panhala' },
    { key: 'panihati', value: 'Panihati' },
    { key: 'panipat', value: 'Panipat' },
    { key: 'panna', value: 'Panna' },
    { key: 'panoor', value: 'Panoor' },
    { key: 'panskura', value: 'Panskura' },
    { key: 'pantnagar', value: 'Pantnagar' },
    { key: 'panvel', value: 'Panvel' },
    { key: 'paradip', value: 'Paradip' },
    { key: 'paramakudi', value: 'Paramakudi' },
    { key: 'paramankurichi', value: 'Paramankurichi' },
    { key: 'parbhani', value: 'Parbhani' },
    { key: 'parli_vaijnath', value: 'Parli Vaijnath' },
    { key: 'parola', value: 'Parola' },
    { key: 'partapgarh', value: 'Partapgarh' },
    { key: 'parwanoo', value: 'Parwanoo' },
    { key: 'patan', value: 'Patan' },
    { key: 'pathanamthitta', value: 'Pathanamthitta' },
    { key: 'pathankot', value: 'Pathankot' },
    { key: 'pathri', value: 'Pathri' },
    { key: 'patiala', value: 'Patiala' },
    { key: 'patna', value: 'Patna' },
    { key: 'patnitop', value: 'Patnitop' },
    { key: 'patratu', value: 'Patratu' },
    { key: 'pattambi', value: 'Pattambi' },
    { key: 'pattukkottai', value: 'Pattukkottai' },
    { key: 'pattukottai', value: 'Pattukottai' },
    { key: 'patur', value: 'Patur' },
    { key: 'pavagada', value: 'Pavagada' },
    { key: 'payyannur', value: 'Payyannur' },
    { key: 'pehowa', value: 'Pehowa' },
    { key: 'pelling', value: 'Pelling' },
    { key: 'pen', value: 'Pen' },
    { key: 'pench', value: 'Pench' },
    { key: 'peraiyur', value: 'Peraiyur' },
    { key: 'perambalur', value: 'Perambalur' },
    { key: 'perinthalmanna', value: 'Perinthalmanna' },
    { key: 'periyakulam', value: 'Periyakulam' },
    { key: 'peth_naka', value: 'Peth Naka' },
    { key: 'petlad', value: 'Petlad' },
    { key: 'phagwara', value: 'Phagwara' },
    { key: 'phalodi', value: 'Phalodi' },
    { key: 'phaltan', value: 'Phaltan' },
    { key: 'phek', value: 'Phek' },
    { key: 'phillaur', value: 'Phillaur' },
    { key: 'phulabani', value: 'Phulabani' },
    { key: 'phulia', value: 'Phulia' },
    { key: 'phusro', value: 'Phusro' },
    { key: 'pilani', value: 'Pilani' },
    { key: 'pilibhit', value: 'Pilibhit' },
    { key: 'pinjore', value: 'Pinjore' },
    { key: 'pippara', value: 'Pippara' },
    { key: 'pithampur', value: 'Pithampur' },
    { key: 'pithavadi', value: 'Pithavadi' },
    { key: 'pithoragarh', value: 'Pithoragarh' },
    { key: 'piyava', value: 'Piyava' },
    { key: 'pollachi', value: 'Pollachi' },
    { key: 'polur', value: 'Polur' },
    { key: 'pondicherry', value: 'Pondicherry' },
    { key: 'ponnani', value: 'Ponnani' },
    { key: 'ponta_sahib', value: 'Ponta Sahib' },
    { key: 'pooparai', value: 'Pooparai' },
    { key: 'poovar', value: 'Poovar' },
    { key: 'porbandar', value: 'Porbandar' },
    { key: 'port_blair', value: 'Port Blair' },
    { key: 'poshina', value: 'Poshina' },
    { key: 'pragpur', value: 'Pragpur' },
    { key: 'pratapgarh', value: 'Pratapgarh' },
    { key: 'prayagraj', value: 'Prayagraj' },
    { key: 'proddatur', value: 'Proddatur' },
    { key: 'puducherry', value: 'Puducherry' },
    { key: 'pudukkottai', value: 'Pudukkottai' },
    { key: 'pudukottai', value: 'Pudukottai' },
    { key: 'puliangudi', value: 'Puliangudi' },
    { key: 'pullambadi', value: 'Pullambadi' },
    { key: 'punch', value: 'Punch' },
    { key: 'pune', value: 'Pune' },
    { key: 'puranpur', value: 'Puranpur' },
    { key: 'puri', value: 'Puri' },
    { key: 'purnea', value: 'Purnea' },
    { key: 'purnia', value: 'Purnia' },
    { key: 'purulia', value: 'Purulia' },
    { key: 'pusa', value: 'Pusa' },
    { key: 'pusad', value: 'Pusad' },
    { key: 'pushkar', value: 'Pushkar' },
    { key: 'puskhar', value: 'Puskhar' },
    { key: 'puttaparthi', value: 'Puttaparthi' },
    { key: 'puttur_ap', value: 'Puttur AP' },
    { key: 'puttur', value: 'Puttur' },
    { key: 'quilandy', value: 'Quilandy' },
    { key: 'rae_bareli', value: 'Rae Bareli' },
    { key: 'raebareli', value: 'Raebareli' },
    { key: 'rai_bareilly', value: 'Rai Bareilly' },
    { key: 'raichak', value: 'Raichak' },
    { key: 'raichur', value: 'Raichur' },
    { key: 'raiganj', value: 'Raiganj' },
    { key: 'raigarh', value: 'Raigarh' },
    { key: 'raipur', value: 'Raipur' },
    { key: 'raisen', value: 'Raisen' },
    { key: 'rajahmundry', value: 'Rajahmundry' },
    { key: 'rajanagaram', value: 'Rajanagaram' },
    { key: 'rajapalayam', value: 'Rajapalayam' },
    { key: 'rajgarh', value: 'Rajgarh' },
    { key: 'rajgir', value: 'Rajgir' },
    { key: 'rajkot', value: 'Rajkot' },
    { key: 'rajmahal', value: 'Rajmahal' },
    { key: 'rajnandgaon', value: 'Rajnandgaon' },
    { key: 'rajouri', value: 'Rajouri' },
    { key: 'rajpipla', value: 'Rajpipla' },
    { key: 'rajpura', value: 'Rajpura' },
    { key: 'rajsamand', value: 'Rajsamand' },
    { key: 'ram_nagar', value: 'Ram Nagar' },
    { key: 'ramachandrapuram', value: 'Ramachandrapuram' },
    { key: 'ramagundham', value: 'Ramagundham' },
    { key: 'raman_mandi', value: 'Raman Mandi' },
    { key: 'ramanagara', value: 'Ramanagara' },
    { key: 'ramanathapuram', value: 'Ramanathapuram' },
    { key: 'rameshwaram', value: 'Rameshwaram' },
    { key: 'ramgarh', value: 'Ramgarh' },
    { key: 'ramnagar', value: 'Ramnagar' },
    { key: 'rampur', value: 'Rampur' },
    { key: 'rampurhat', value: 'Rampurhat' },
    { key: 'ranaghat', value: 'Ranaghat' },
    { key: 'ranakpur', value: 'Ranakpur' },
    { key: 'ranchi', value: 'Ranchi' },
    { key: 'ranebennur', value: 'Ranebennur' },
    { key: 'raniganj', value: 'Raniganj' },
    { key: 'ranikhet', value: 'Ranikhet' },
    { key: 'ranny', value: 'Ranny' },
    { key: 'ranthambore', value: 'Ranthambore' },
    { key: 'ratangarh', value: 'Ratangarh' },
    { key: 'ratlam', value: 'Ratlam' },
    { key: 'ratnagiri', value: 'Ratnagiri' },
    { key: 'ravangla', value: 'Ravangla' },
    { key: 'rayachoty', value: 'Rayachoty' },
    { key: 'rayagada', value: 'Rayagada' },
    { key: 'rewa', value: 'Rewa' },
    { key: 'rewari', value: 'Rewari' },
    { key: 'rishikesh', value: 'Rishikesh' },
    { key: 'rishyap', value: 'Rishyap' },
    { key: 'rohetgarh', value: 'Rohetgarh' },
    { key: 'rohtak', value: 'Rohtak' },
    { key: 'roorkee', value: 'Roorkee' },
    { key: 'ropar', value: 'Ropar' },
    { key: 'rourkela', value: 'Rourkela' },
    { key: 'rudrapur', value: 'Rudrapur' },
    { key: 'rupnagar', value: 'Rupnagar' },
    { key: 'sagar', value: 'Sagar' },
    { key: 'saharanpur', value: 'Saharanpur' },
    { key: 'saharsa', value: 'Saharsa' },
    { key: 'sahibganj', value: 'Sahibganj' },
    { key: 'sailu', value: 'Sailu' },
    { key: 'sajan', value: 'Sajan' },
    { key: 'sakoli', value: 'Sakoli' },
    { key: 'sakri', value: 'Sakri' },
    { key: 'salem', value: 'Salem' },
    { key: 'samalkha', value: 'Samalkha' },
    { key: 'samana', value: 'Samana' },
    { key: 'samastipur', value: 'Samastipur' },
    { key: 'sambalpur', value: 'Sambalpur' },
    { key: 'sambhal', value: 'Sambhal' },
    { key: 'sanand', value: 'Sanand' },
    { key: 'sandila', value: 'Sandila' },
    { key: 'sangamner', value: 'Sangamner' },
    { key: 'sangareddi', value: 'Sangareddi' },
    { key: 'sangli fata', value: 'Sangli Fata' },
    { key: 'sangli', value: 'Sangli' },
    { key: 'sangola', value: 'Sangola' },
    { key: 'sangrur', value: 'Sangrur' },
    { key: 'santipur', value: 'Santipur' },
    { key: 'saoner', value: 'Saoner' },
    { key: 'saputara', value: 'Saputara' },
    { key: 'saraikela', value: 'Saraikela' },
    { key: 'sarangpur', value: 'Sarangpur' },
    { key: 'sardarshahar', value: 'Sardarshahar' },
    { key: 'sasan_gir', value: 'Sasan Gir' },
    { key: 'sasaram', value: 'Sasaram' },
    { key: 'satara', value: 'Satara' },
    { key: 'satna', value: 'Satna' },
    { key: 'sattal', value: 'Sattal' },
    { key: 'saunda', value: 'Saunda' },
    { key: 'sawai_madhopur', value: 'Sawai Madhopur' },
    { key: 'sawaimadhopur', value: 'SawaiMadhopur' },
    { key: 'sawantwadi', value: 'Sawantwadi' },
    { key: 'sawarde', value: 'Sawarde' },
    { key: 'sayla', value: 'Sayla' },
    { key: 'sayra', value: 'Sayra' },
    { key: 'secunderabad', value: 'Secunderabad' },
    { key: 'sehore', value: 'Sehore' },
    { key: 'selu', value: 'Selu' },
    { key: 'seoni', value: 'Seoni' },
    { key: 'sevagram', value: 'Sevagram' },
    { key: 'shahagad', value: 'Shahagad' },
    { key: 'shahapur', value: 'Shahapur' },
    { key: 'shahdol', value: 'Shahdol' },
    { key: 'shahjahanpur', value: 'Shahjahanpur' },
    { key: 'shahpura', value: 'Shahpura' },
    { key: 'shajapur', value: 'Shajapur' },
    { key: 'shaktinagar', value: 'Shaktinagar' },
    { key: 'shamli', value: 'Shamli' },
    { key: 'shantiniketan', value: 'Shantiniketan' },
    { key: 'sharavanbelgola', value: 'Sharavanbelgola' },
    { key: 'shegaon', value: 'Shegaon' },
    { key: 'sheikhpura', value: 'Sheikhpura' },
    { key: 'shelapur', value: 'Shelapur' },
    { key: 'sheopur', value: 'Sheopur' },
    { key: 'shikohabad', value: 'Shikohabad' },
    { key: 'shillong', value: 'Shillong' },
    { key: 'shimla', value: 'Shimla' },
    { key: 'shimlipal', value: 'Shimlipal' },
    { key: 'shimoga', value: 'Shimoga' },
    { key: 'shirdi', value: 'Shirdi' },
    { key: 'shivamogga', value: 'Shivamogga' },
    { key: 'shivanasamudra', value: 'Shivanasamudra' },
    { key: 'shivpuri', value: 'Shivpuri' },
    { key: 'shravanabelagola', value: 'Shravanabelagola' },
    { key: 'shrirampur', value: 'Shrirampur' },
    { key: 'shrirangapattana', value: 'Shrirangapattana' },
    { key: 'siana', value: 'Siana' },
    { key: 'siddapur', value: 'Siddapur' },
    { key: 'siddhpur', value: 'Siddhpur' },
    { key: 'siddipet', value: 'Siddipet' },
    { key: 'sidhi', value: 'Sidhi' },
    { key: 'sidlaghatta', value: 'Sidlaghatta' },
    { key: 'sikandrabad', value: 'Sikandrabad' },
    { key: 'sikar', value: 'Sikar' },
    { key: 'silchar', value: 'Silchar' },
    { key: 'siliguri', value: 'Siliguri' },
    { key: 'silvassa', value: 'Silvassa' },
    { key: 'singhana', value: 'Singhana' },
    { key: 'singrauli', value: 'Singrauli' },
    { key: 'sircilla', value: 'Sircilla' },
    { key: 'sirkazhi', value: 'Sirkazhi' },
    { key: 'sirohi', value: 'Sirohi' },
    { key: 'sironj', value: 'Sironj' },
    { key: 'sirsa', value: 'Sirsa' },
    { key: 'sitamarhi', value: 'Sitamarhi' },
    { key: 'sitapur', value: 'Sitapur' },
    { key: 'siuri', value: 'Siuri' },
    { key: 'sivaganga_district', value: 'Sivaganga District' },
    { key: 'sivakasi', value: 'Sivakasi' },
    { key: 'sivasagar', value: 'Sivasagar' },
    { key: 'siwan', value: 'Siwan' },
    { key: 'solan', value: 'Solan' },
    { key: 'solapur', value: 'Solapur' },
    { key: 'sonauli', value: 'Sonauli' },
    { key: 'sonipat', value: 'Sonipat' },
    { key: 'sonkhed', value: 'Sonkhed' },
    { key: 'sreerampur', value: 'Sreerampur' },
    { key: 'sri_dungargarh', value: 'Sri Dungargarh' },
    { key: 'sri_ganganagar', value: 'Sri Ganganagar' },
    { key: 'srikakulam', value: 'Srikakulam' },
    { key: 'srinagar', value: 'Srinagar' },
    { key: 'srivilliputhur', value: 'Srivilliputhur' },
    { key: 'sujangarh', value: 'Sujangarh' },
    { key: 'sultanpur', value: 'Sultanpur' },
    { key: 'sulthan_bathery', value: 'Sulthan Bathery' },
    { key: 'sundar_nagar', value: 'Sundar Nagar' },
    { key: 'sundargarh', value: 'Sundargarh' },
    { key: 'sunder_nagar', value: 'Sunder Nagar' },
    { key: 'sunderban', value: 'Sunderban' },
    { key: 'supaul', value: 'Supaul' },
    { key: 'surat', value: 'Surat' },
    { key: 'suratgarh', value: 'Suratgarh' },
    { key: 'surendranagar', value: 'Surendranagar' },
    { key: 'suri', value: 'Suri' },
    { key: 'suryapet', value: 'Suryapet' },
    { key: 'tadepalligudem', value: 'Tadepalligudem' },
    { key: 'tajpur', value: 'Tajpur' },
    { key: 'takari', value: 'Takari' },
    { key: 'talegadh', value: 'Talegadh' },
    { key: 'talegaon', value: 'Talegaon' },
    { key: 'taliparamba', value: 'Taliparamba' },
    { key: 'talluru', value: 'Talluru' },
    { key: 'tamluk', value: 'Tamluk' },
    { key: 'tanda', value: 'Tanda' },
    { key: 'tandur', value: 'Tandur' },
    { key: 'tanjore', value: 'Tanjore' },
    { key: 'tanuku', value: 'Tanuku' },
    { key: 'tapola', value: 'Tapola' },
    { key: 'tarapith', value: 'Tarapith' },
    { key: 'tarkeswar', value: 'Tarkeswar' },
    { key: 'tarn_taran', value: 'Tarn Taran' },
    { key: 'tasgaon', value: 'Tasgaon' },
    { key: 'tehri', value: 'Tehri' },
    { key: 'tenkasi', value: 'Tenkasi' },
    { key: 'tezpur', value: 'Tezpur' },
    { key: 'tezu', value: 'Tezu' },
    { key: 'thalassery', value: 'Thalassery' },
    { key: 'thamarassery', value: 'Thamarassery' },
    { key: 'thane', value: 'Thane' },
    { key: 'thanjavur', value: 'Thanjavur' },
    { key: 'thattarmadam', value: 'Thattarmadam' },
    { key: 'thekkady', value: 'Thekkady' },
    { key: 'theni', value: 'Theni' },
    { key: 'thennilai', value: 'Thennilai' },
    { key: 'thirumangalam', value: 'Thirumangalam' },
    { key: 'thirunallar', value: 'Thirunallar' },
    { key: 'thirupattur', value: 'Thirupattur' },
    { key: 'thiruvalla', value: 'Thiruvalla' },
    { key: 'thiruvananthapuram', value: 'Thiruvananthapuram' },
    { key: 'thiruvanmiyur', value: 'Thiruvanmiyur' },
    { key: 'thirvannamalai', value: 'Thirvannamalai' },
    { key: 'thodupuzha', value: 'Thodupuzha' },
    { key: 'thoothukudi', value: 'Thoothukudi' },
    { key: 'thottiyam', value: 'Thottiyam' },
    { key: 'thoubal', value: 'Thoubal' },
    { key: 'thrissur', value: 'Thrissur' },
    { key: 'thuraiyur', value: 'Thuraiyur' },
    { key: 'tikamgarh', value: 'Tikamgarh' },
    { key: 'tindivanam', value: 'Tindivanam' },
    { key: 'tinsukia', value: 'Tinsukia' },
    { key: 'tiptur', value: 'Tiptur' },
    { key: 'tiruchchirappalli', value: 'Tiruchchirappalli' },
    { key: 'tiruchirapalli', value: 'Tiruchirapalli' },
    { key: 'tirunelveli', value: 'Tirunelveli' },
    { key: 'tirupati', value: 'Tirupati' },
    { key: 'tiruppur', value: 'Tiruppur' },
    { key: 'tirupur', value: 'Tirupur' },
    { key: 'tirur', value: 'Tirur' },
    { key: 'tiruttani', value: 'Tiruttani' },
    { key: 'tiruvannamalai', value: 'Tiruvannamalai' },
    { key: 'tirwaganj', value: 'Tirwaganj' },
    { key: 'tisgaon', value: 'Tisgaon' },
    { key: 'titagarh', value: 'Titagarh' },
    { key: 'tohana', value: 'Tohana' },
    { key: 'tonk', value: 'Tonk' },
    { key: 'triprayar', value: 'Triprayar' },
    { key: 'tumakuru', value: 'Tumakuru' },
    { key: 'tumkur', value: 'Tumkur' },
    { key: 'tuni', value: 'Tuni' },
    { key: 'udaipur', value: 'Udaipur' },
    { key: 'udayagiri', value: 'Udayagiri' },
    { key: 'udgir', value: 'Udgir' },
    { key: 'udhagamandalam', value: 'Udhagamandalam' },
    { key: 'udhampur', value: 'Udhampur' },
    { key: 'udumalaipettai', value: 'Udumalaipettai' },
    { key: 'udupi', value: 'Udupi' },
    { key: 'ujjain', value: 'Ujjain' },
    { key: 'ulhasnagar', value: 'Ulhasnagar' },
    { key: 'umaria', value: 'Umaria' },
    { key: 'umarkhed', value: 'Umarkhed' },
    { key: 'umred', value: 'Umred' },
    { key: 'umreth', value: 'Umreth' },
    { key: 'una', value: 'Una' },
    { key: 'undri', value: 'Undri' },
    { key: 'unguturu', value: 'Unguturu' },
    { key: 'unjha', value: 'Unjha' },
    { key: 'unnao', value: 'Unnao' },
    { key: 'upleta', value: 'Upleta' },
    { key: 'uppoor', value: 'Uppoor' },
    { key: 'uppur', value: 'Uppur' },
    { key: 'uran_islampur', value: 'Uran Islampur' },
    { key: 'uranipuram', value: 'Uranipuram' },
    { key: 'uttarkashi', value: 'Uttarkashi' },
    { key: 'vadodara', value: 'Vadodara' },
    { key: 'vagamon', value: 'Vagamon' },
    { key: 'vaijapur', value: 'Vaijapur' },
    { key: 'valluru', value: 'Valluru' },
    { key: 'valsad', value: 'Valsad' },
    { key: 'vanda', value: 'Vanda' },
    { key: 'vapi', value: 'Vapi' },
    { key: 'varanasi', value: 'Varanasi' },
    { key: 'varkala', value: 'Varkala' },
    { key: 'vasai-virar', value: 'Vasai-Virar' },
    { key: 'vatakara', value: 'Vatakara' },
    { key: 'veeravasaram', value: 'Veeravasaram' },
    { key: 'velankanni', value: 'Velankanni' },
    { key: 'vellakovil', value: 'Vellakovil' },
    { key: 'vellore', value: 'Vellore' },
    { key: 'venthanpatti', value: 'Venthanpatti' },
    { key: 'veraval', value: 'Veraval' },
    { key: 'vidisha', value: 'Vidisha' },
    { key: 'vijainagar', value: 'Vijainagar' },
    { key: 'vijayapura', value: 'Vijayapura' },
    { key: 'vijayawada', value: 'Vijayawada' },
    { key: 'vikramgadh', value: 'Vikramgadh' },
    { key: 'viluppuram', value: 'Viluppuram' },
    { key: 'viramgam', value: 'Viramgam' },
    { key: 'visakhapatnam', value: 'Visakhapatnam' },
    { key: 'vishakapatnam', value: 'Vishakapatnam' },
    { key: 'vizianagaram', value: 'Vizianagaram' },
    { key: 'vyara', value: 'Vyara' },
    { key: 'wadsa', value: 'Wadsa' },
    { key: 'wadwani', value: 'Wadwani' },
    { key: 'wanaparthy', value: 'Wanaparthy' },
    { key: 'wankaner', value: 'Wankaner' },
    { key: 'warangal', value: 'Warangal' },
    { key: 'wardha', value: 'Wardha' },
    { key: 'warora', value: 'Warora' },
    { key: 'warud', value: 'Warud' },
    { key: 'washi', value: 'Washi' },
    { key: 'washim', value: 'Washim' },
    { key: 'watrap', value: 'Watrap' },
    { key: 'wayanad', value: 'Wayanad' },
    { key: 'wokha', value: 'Wokha' },
    { key: 'yamunotri', value: 'Yamunotri' },
    { key: 'yanam', value: 'Yanam' },
    { key: 'yavatmal', value: 'Yavatmal' },
    { key: 'yemmiganur', value: 'Yemmiganur' },
    { key: 'yeola', value: 'Yeola' },
    { key: 'yercaud', value: 'Yercaud' },
    { key: 'yevla', value: 'Yevla' },
    { key: 'yuksom', value: 'Yuksom' },
    { key: 'zira', value: 'Zira' },
    { key: 'zunheboto', value: 'Zunheboto' },
];

export const documentNameMap = {
    DEATH_CERTIFICATE: 'Death Certificate',
    MEDICAL_PRESCRIPTION: 'Medical Prescription',
    POST_MORTEM_REPORT: 'Post Mortem Report',
    NO_OBJECTION_LETTER: 'No Objection Letter',
    KYC_DOCUMENTS: 'KYC Documents',
    HOSPITAL_MEDICAL_PAPERS: 'Hospital medical papers',
    INVESTIGATION_REPORTS: 'Investigation Reports',
    MORTAL_REMAINS_REPATRIATION: 'Mortal remains Repatriation',
    PAYMENT_INVOICE: 'Payment Invoice',
    VENDOR_INVESTIGATION_REPORT: 'Vendor Investigation Report',
    ALL_INDIA_PERMIT: 'All India permit',
    AML_ADDRESS_PROOF: 'AML address proof',
    AML_ID_PROOF: 'AML id proof',
    AML_PHOTO: 'AML photo',
    BANK_FORECLOSURE_STATEMENT: 'Bank foreclosure statement',
    CANCELLED_CHEQUE: 'Cancelled check',
    DL: 'DL',
    FIR: 'FIR',
    FITNESS_CERTIFICATE: 'Fitness certificate',
    ITR: 'ITR',
    LEGAL_HEIR_CERTIFICATE: 'Legar heir certificate',
    LETTER_OF_INDEMNITY: 'Letter of indemnity',
    LOAD_CHALLAN: 'Load challan',
    LETTER_OF_SUBROGATION: 'Letter of subrogation',
    NOC_FROM_FINANCER_AFTER_SETTLEMENT: 'Noc from financer after settlement',
    NOC_FROM_FINANCER_FOR_SETTLEMENT: 'Noc from financer for settlement',
    NON_REPOSSESSION_LETTER_FROM_FINANCER:
        'Non repossession letter from financer',
    NTC: 'NTC',
    ORIGINAL_SET_OF_KEYS: 'Original set of keys',
    POST_CLAIM_SETTLEMENT_LETTER_TO_NCRB:
        'Post claim settlement letter to NCRB',
    POST_CLAIM_SETTLEMENT_LETTER_TO_POLICE_STATION:
        'Post claim settlement letter to police station',
    POST_CLAIM_SETTLEMENT_LETTER_TO_RTO: 'Post claim settlement letter to RTO',
    PYP: 'PYP',
    RC: 'RC',
    RELATIONSHIP_PROOF: 'Relationship proof',
    CLAIM_FORM: 'Claim form',
    CONSENT_NON_REPAIR_CLAIMS: 'Consent non repair claims',
    FINANCIER_NEFT_DETAILS: 'Financer neft details',
    REJECTION_LETTER: 'Rejection letter',
    STATE_PERMIT: 'State permit',
    TAX_PAID_RECEIPT: 'Tax paid receipt',
    THEFT_INTIMATION_LETTER_TO_NCRB: 'Theft information from NCRB',
    THEFT_INTIMATION_LETTER_TO_RTO: 'Theft information from RTO',
    TRANSFER_FORM: 'Transfer form',
    VEHICLE_PURCHASE_INVOICE: 'Vehicle purchase invoice',
    VEHICLE_DELIVERY_CHALLAN: 'Vehicle delivery challan',
    INSURED_STATEMENT: 'Insured statement',
    EYE_WITNESS_STATEMENT: 'Eye witness statement',
    SPOT_PHOTOS: 'Spot photos',
    QST_REPORT: 'QST report',
    RC_EXTRACT: 'RC Extract',
    RECOVERY_PHOTOS: 'Recovery photos',
    WITHDRAWAL_LETTER: 'Withdrawl letter',
    APPROVAL_EMAIL: 'Approval email',
    OTHER: 'Other',
    COMMUNICATION_LETTER: 'Communication letter',
    COMPUTATION_SHEET: 'Computation Sheet',
    AMBULANCE_BILL: 'Ambulance Bill',
    DISCHARGE_VOUCHER: 'Discharge voucher',
    FLIGHT_TICKET: 'Flight ticket',
    HOSPITAL_BILL: 'Hospital Bill',
    MEDICINE_BILL: 'Medicine Bill',
    ORIGINAL_PRESCRIPTION: 'Original Prescription',
    PAYMENT_RECEIPT: 'Payment Receipt',
    BANK_ACCOUNT_SUMMARY: 'Bank Account Summary',
    BANK_ACCOUNT_STATEMENT: 'Bank account statement',
    HOSPITAL_DISCHARGE_SUMMARY: 'Hospital discharge summary',
    PURCHASE_INVOICE: 'Device purchase invoice',
    HOTEL_STAY_INVOICE: 'Hotel stay invoice',
    PROOF_OF_OWNERSHIP: 'Proof of ownership',
    SALARY_SLIPS: 'Salary slips',
    TRAVEL_TICKETS: 'Travel tickets',
    FIRE_BRIGADE_REPORT: 'Fire brigade report',
    SERVICE_TERMINATION_LETTER: 'Service termination letter',
    REPORTS: 'Reports',
    DISABILITY_CERTIFICATE: 'Disability certificate',
    DISCHARGE_SUMMARY: 'Discharge summary',
    EMPLOYER_CONFIRMATION: 'Employer confirmation',
    PROGRESS_NOTES: 'Progress notes',
    HOSPITAL_FINAL_BILL: 'Hospital final bill',
    MEDICAL_BILLS_PRE_HOSPITALIZATION: 'Medical bills pre hospitalization',
    MEDICAL_BILLS_POST_HOSPITALIZATION: 'Medical bills post hospitalization',
    AIRLINES_NO_SHOW_CONFIRMATION: 'Airlines no show confirmation',
    REFUND_DETAILS: 'Refund details',
    EXPENSE_INVOICE: 'Expense invoice',
    MEDICAL_INVOICE:
        'Medical Bills (Consultation Receipt, Pharmacy bills, Diagnostic bills)',
    DIAGNOSIS_REPORT: 'Diagnosis report',
    AMORTIZATION_STATEMENT: 'Amortization statement',
    DAMAGE_VIDEO: 'Damage Video',
    P_SLIP: 'P Slip',
    SERIAL_NUMBER_IMAGE: 'Serial Number Image',
    INVOICE: 'Invoice',
    CONFIRMATION_OF_DELAY_BY_COMMON_CARRIER:
        'Confirmation of delay by common carrier',
    NEW_FLIGHT_TICKET: 'New Flight Ticket',
    COURT_PETITION_AND_JUDGEMENT: 'Court Petition & Judgement',
    EMERGENCY_STAY_INVOICE: 'Emergency Stay Invoice',
    CONFIRMATION_OF_DELAY_FROM_AIRLINES: 'Confirmation of delay from airlines',
    CONFIRMATION_OF_DELAY_BY_THE_COMMON_CARRIER:
        'Confirmation of delay by the common carrier',
    CONFIRMATION_OF_CANCELLATION_FROM_THE_COMMON_CARRIER:
        'Confirmation of cancellation from the common carrier',
    CONFIRMATION_FROM_AIRLINES: 'Confirmation from airlines',
    MISSED_FLIGHT_TICKET: 'Missed Flight Ticket',
    TRIP_CANCELLATION_PROOF: 'Trip Cancellation Proof',
    NATURAL_CATASTROPHE_PROOF: 'Natural Catastrophe Proof',
    REPAIR_INVOICE: 'Repair Invoice',
    REPAIR_FRONT_IMAGE: 'Repaired Device Image - Front',
    REPAIR_BACK_IMAGE: 'Repaired Device Image - Back',
    REPAIR_ESTIMATE: 'Repair Estimate',
    COVID_POSITIVE_REPORT: 'Covid Positive Report',
    PLAN_PURCHASE_INVOICE: 'Plan Purchase Invoice',
    DAMAGE_FRONT_IMAGE: 'Front side of damaged mobile',
    DAMAGE_BACK_IMAGE: 'Back side of damaged mobile',
    RESIDENTIAL_PROOF: 'Residential Proof',
    PROOF_OF_RELATIONSHIP_WITH_INSURED: 'Proof Of Relationship With Insured',
    INVOICE_STOLEN_DAMAGED_ITEMS: 'Invoice Of Stolen Damaged Items',
    VEHICLE_IMAGES_AFTER_ACCIDENT: 'Vehicle Images After Accident',
    VEHICLE_IMAGES_BEFORE_TRIP: 'Vehicle Images Before Trip',
    SHOPKEEPERS_LICENSE: 'Shopkeeper’s license',
    DAMAGED_GOOD_PHOTO_VIDEO: 'Photos/Video of damaged goods',
    FAR: 'Fire Accidental report (F.A.R)',
    EQUIPMENT_IMAGE: 'Image of Damaged/Stolen Goods',
    AMBULANCE_INVOICE: 'Ambulance Invoice',
    CONSULTATION_REPORT: 'Consultation Report',
    CONSULTATION_INVOICE: 'Consultation/Diagnostics Invoice',
    INCIDENT_DETAIL_100_WORDS: 'Incident Detail in 100 words',
    DAMAGE_PHOTO: 'Damage Photo',
    REPLACE_DEVICE_INVOICE: 'Replaced Device Invoice',
    AMAZON_DEVICE_PURCHASE_INVOICE: 'Amazon device purchase Invoice',
    ACKO_PLAN_AMAZON_PURCHASE_INVOICE: 'Acko plan amazon purchase Invoice',
    REPLACED_DEVICE_AMAZON_INVOICE: 'Replaced device Amazon Invoice',
    DEVICE_AMAZON_P_SLIP: 'Device Amazon P-slip',
    ACKO_PLAN_AMAZON_P_SLIP: 'Acko plan Amazon P-slip',
    INDOOR_CASE_PAPERS: 'Indoor Case Papers',
    MEDICAL_CERTIFICATE: 'Medical Certificate',
    REPAIR_IMAGE: 'Repair Images/Videos',
    BRAND_ESTIMATION:
        'Estimation from the brand or GST authorized service center',
    EXPERIENCE_LETTER: 'Experience Letter',
    PF_STATEMENT_SALARY_SLIPS: 'PF statements or Salary Slips',
    RELIEVING_LETTER: 'Relieving Letter',
    NO_SHOW_TRAVEL_CERTIFICATE: 'No Show Travel Certificate',
};

export const categoryMap = {
    mobile: 'Mobile',
    laundry: 'Laundry',
    cooking: 'Cooking',
    television: 'Television',
    dishwashers: 'Dishwashers',
    refrigerator: 'Refrigerator',
    microwave_oven: 'Microwave Oven',
    air_conditioner: 'Air Conditioner',
    misc_kitchen: 'Misc Kitchen',
    audio: 'Audio',
    camera: 'Camera',
    appliance: 'Appliance',
    laptop: 'Laptop',
    air_cooler: 'Air Cooler',
    echo: 'Echo',
    fireTVStick: 'FireTVStick',
    kindle: 'Kindle',
    headphone: 'Headphone',
    home_theatre: 'Home Theatre',
    speaker: 'Speaker',
    computer_speaker: 'Computer Speaker',
    headset: 'Headset',
    DSLR_and_mirrorless_cameras: 'Dslr',
    point_and_shoot_camera: 'Camera',
    water_purifier: 'Water Purifier',
    desktop: 'Desktop',
    handy_camera: 'Handy Camera',
    printer: 'Printer',
    vaccum_cleaner: 'Vaccum Cleaner',
    dslr: 'Dslr',
    music_system: 'Music System',
    chimney: 'Chimney',
    induction: 'Induction',
    water_heater: 'Water Heater',
    tablets: 'Tablets',
    wearables: 'Wearables',
    air_purifier: 'Air Purifier',
    dryer: 'Dryer',
    food_processor: 'Food Processor',
    fryer: 'Fryer',
    otg: 'Otg',
    washing_machine: 'Washing Machine',
    audio_system: 'Audio System',
    blender_chopper: 'Blender Chopper',
    coffee_maker: 'Coffee Maker',
    dough_maker: 'Dough Maker',
    electric_iron: 'Electric Iron',
    electric_kettle: 'Electric Kettle',
    electric_rice_cooker: 'Electric Rice Cooker',
    electric_room_heater: 'Electric Room Heater',
    induction_cooktop: 'Induction Cooktop',
    kitchen_chimney: 'Chimney',
    scanner: 'Scanner',
    roti_maker: 'Roti Maker',
    sandwich_maker: 'Sandwich Maker',
    toaster: 'Toaster',
    mixer: 'Mixer',
    juicer: 'Juicer',
    grinder: 'Grinder',
    small_appliances: 'Appliance',
    smart_watch: 'Smart Watch',
    musical_instrument: 'Musical Instrument',
    treadmill: 'Treadmill',
    air_conditioners: 'Air Conditioner',
};
